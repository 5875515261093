// Here you can add other styles


.table-text-center {
  text-align: center !important;
}

.table-text-left {
  text-align: left !important;
}

.table-text-right {
  text-align: right !important;
}

.tall-row {
  height: 65px;
  vertical-align: bottom;
}

.text-formula {
  font-size: 22px;
}

.text-explanation {
  text-align: justify;
}

.progress-row {
  margin: auto;
  padding-top: 60px;
}

.financial-progress-bar {
  padding: 0;
  margin-bottom: 60px;
}

.financial-progress-bar .progress-bar {
  background: $gradient-progress-1;
}

.financial-progress-bar-2 {
  padding: 0;
}

.financial-progress-bar-2 .progress-bar {
  background: $gradient-progress-2;
}

.financial-progress-bar-3 {
  padding: 0;
}

.financial-progress-bar-3 .progress-bar {
  background: $gradient-progress-3;
}

.ratio-index-left {
  width: 50%;
  text-align: left;
}

.ratio-index-right {
  width: 50%;
  text-align: right;
}

.ratio-index-center {
  width: 50%;
  text-align: center;
}

@keyframes fadeIn  {
  from {
    opacity: 0;
  } to {
    opacity: 1;
      }
}

.home-div {
  margin-top: 10%;
  opacity: 1;
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}

.home-div img{
  width:100%
}


.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
}

.semaphore-warning {
  background-color: $warning;
}

.semaphore-alert {
  background-color: $danger;
}

.semaphore-success {
  background: $success;
}

.sidebar-nav .nav-link {
  //overflow: hidden;
  //text-overflow: ellipsis !important;
  white-space: unset !important;
}

.comparative-icon {
  margin-right: 4px
}

.dropdown-item {
  cursor: pointer;
}

.tall-row-2{
  height: 45px;
  vertical-align: top;
}

.rs-nav-horizontal {
  white-space: normal !important;
}

.btn-checkbox-group {
  border-style: solid;
  border-width: 1px;
  border-radius: 7px;
  border-color: #1675e0;
  //overflow: hidden;

  .rs-checkbox:not(:first-child) {
    border-style: solid;
    border-width: 0 0 0 1px;
    border-color: #1675e0;
  }

  :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  :last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .rs-checkbox {
    margin-left: 0 !important;
  }

  .rs-checkbox-control {
    display: none;
  }

  .rs-checkbox-inline {
    font-size: 14px;
    margin: 0 !important;
  }

  .rs-checkbox-checker {
    padding: 9px !important;
    color: #1675e0;
    min-height: fit-content;
  }

  .rs-checkbox-checked {
    background: #1675e0;
    .rs-checkbox-checker {
      color: #ffffff;
    }
    :first-child {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
    }
  }
}

@media only screen and (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
}

@media only screen and (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
}

@media only screen and (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
}



